.vision,
.proximity {
  height: auto;
}

.snapped {
  background: transparent;
}

@media only screen and (max-width: 767px) {
  :global(.ui.stackable.grid)
    > :global(.row).snapped
    > :global(.wide.column):first-child {
    position: fixed;
    top: 42px;
    left: 0;
    z-index: 100;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  :global(.ui.stackable.grid)
    > :global(.row).snapped
    > :global(.wide.column):nth-child(2) {
    margin-top: 72px !important;
  }
}

@media only screen and (min-width: 768px) {
  .vision,
  .proximity {
    height: 360px;
  }

  :global(.ui.grid) > :global(.row).snapped {
    position: fixed;
    top: 42px;
    z-index: 100;
    width: 751px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .snapped + :global(.column),
  .snapped + :global(.column) + :global(.column) {
    margin-top: 72px;
  }
}

@media only screen and (min-width: 992px) {
  :global(.ui.grid) > :global(.row).snapped {
    width: 961px !important;
  }
}

@media only screen and (min-width: 1200px) {
  :global(.ui.grid) > :global(.row).snapped {
    width: 1155px !important;
  }

  .snapped + :global(.column) + :global(.column) + :global(.column) {
    margin-top: 72px;
  }
}
