body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root #walktour-portal #walktour-tooltip-container button {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root #walktour-portal #walktour-tooltip-container button {
  background: #921222 !important;
  color: white !important;
  border-color: white !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ui.top.right.attached.label {
  height: 30px;
  width: 30px;
}

:root .countryLabel {
  padding: 0 0 0 0 !important;
  background-color: transparent !important;
  font-size: var(--country-label-font-size) !important;
  color: white !important;
  text-shadow: 2px 0px 2px black !important;
  width: 60px !important;
  margin-top: -20px !important;
  margin-left: -30px !important;
  text-align: center !important;
  /* outline: 1px solid black !important; */
}

:root .countryLabel p {
  padding: 0 !important;
  margin: 0 !important;
}

:root .leaflet-control-layers-toggle {
  background-color: #c0182c !important;
}

:root #walktour-portal #walktour-tooltip-container button {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root #walktour-portal #walktour-tooltip-container button {
  background: #921222 !important;
  color: white !important;
  border-color: white !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root .rc-leaflet-wrap {
  height: 600px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.top.attached.segment + .ui.bottom.tabular.menu {
  width: calc(100% + (1px * 2));
}
.ui.attached.menu {
  max-width: calc(100% - (-1px * 2));
  width: calc(100% - (-1px * 2));
}
.ui.attached.segment {
  max-width: calc(100% - (-1px * 2));
  width: calc(100% - (-1px * 2));
}
.ui.attached.steps {
  max-width: calc(100% + (-1px * 2));
  width: calc(100% + (-1px * 2)) !important;
}
.ui.attached.table {
  max-width: calc(100% - (-1px * 2));
  width: calc(100% - (-1px * 2));
}
.ui.basic.webo.grey.active.button {
  background: 0 0 !important;
  box-shadow: 0 0 0 1px #8a9aa2 inset !important;
  color: #7e8d95 !important;
}
.ui.basic.webo.grey.button {
  box-shadow: 0 0 0 1px #9aa6ac inset !important;
  color: #9aa6ac !important;
}
.ui.basic.webo.grey.button:active {
  box-shadow: 0 0 0 1px #7e8d95 inset !important;
  color: #7e8d95 !important;
}
.ui.basic.webo.grey.button:focus {
  background: 0 0 !important;
  box-shadow: 0 0 0 1px #81939c inset !important;
  color: #8b9aa2 !important;
}
.ui.basic.webo.grey.button:hover {
  background: 0 0 !important;
  box-shadow: 0 0 0 1px #8b9aa2 inset !important;
  color: #8b9aa2 !important;
}
.ui.basic.webo.grey.buttons .active.button {
  background: 0 0 !important;
  box-shadow: 0 0 0 1px #8a9aa2 inset !important;
  color: #7e8d95 !important;
}
.ui.basic.webo.grey.buttons .button {
  box-shadow: 0 0 0 1px #9aa6ac inset !important;
  color: #9aa6ac !important;
}
.ui.basic.webo.grey.buttons .button:active {
  box-shadow: 0 0 0 1px #7e8d95 inset !important;
  color: #7e8d95 !important;
}
.ui.basic.webo.grey.buttons .button:focus {
  background: 0 0 !important;
  box-shadow: 0 0 0 1px #81939c inset !important;
  color: #8b9aa2 !important;
}
.ui.basic.webo.grey.buttons .button:hover {
  background: 0 0 !important;
  box-shadow: 0 0 0 1px #8b9aa2 inset !important;
  color: #8b9aa2 !important;
}
.ui.basic.webo.greyish.active.button {
  background: 0 0 !important;
  box-shadow: 0 0 0 1px #908c95 inset !important;
  color: #838088 !important;
}
.ui.basic.webo.greyish.button {
  box-shadow: 0 0 0 1px #9d9aa1 inset !important;
  color: #9d9aa1 !important;
}
.ui.basic.webo.greyish.button:active {
  box-shadow: 0 0 0 1px #838088 inset !important;
  color: #838088 !important;
}
.ui.basic.webo.greyish.button:focus {
  background: 0 0 !important;
  box-shadow: 0 0 0 1px #88848e inset !important;
  color: #908c95 !important;
}
.ui.basic.webo.greyish.button:hover {
  background: 0 0 !important;
  box-shadow: 0 0 0 1px #908c95 inset !important;
  color: #908c95 !important;
}
.ui.basic.webo.greyish.buttons .active.button {
  background: 0 0 !important;
  box-shadow: 0 0 0 1px #908c95 inset !important;
  color: #838088 !important;
}
.ui.basic.webo.greyish.buttons .button {
  box-shadow: 0 0 0 1px #9d9aa1 inset !important;
  color: #9d9aa1 !important;
}
.ui.basic.webo.greyish.buttons .button:active {
  box-shadow: 0 0 0 1px #838088 inset !important;
  color: #838088 !important;
}
.ui.basic.webo.greyish.buttons .button:focus {
  background: 0 0 !important;
  box-shadow: 0 0 0 1px #88848e inset !important;
  color: #908c95 !important;
}
.ui.basic.webo.greyish.buttons .button:hover {
  background: 0 0 !important;
  box-shadow: 0 0 0 1px #908c95 inset !important;
  color: #908c95 !important;
}
.ui.basic.webo.red.active.button {
  background: 0 0 !important;
  box-shadow: 0 0 0 1px #b4091e inset !important;
  color: #921222 !important;
}
.ui.basic.webo.red.button {
  box-shadow: 0 0 0 1px #c0172c inset !important;
  color: #c0172c !important;
}
.ui.basic.webo.red.button:active {
  box-shadow: 0 0 0 1px #921222 inset !important;
  color: #921222 !important;
}
.ui.basic.webo.red.button:focus {
  background: 0 0 !important;
  box-shadow: 0 0 0 1px #a90519 inset !important;
  color: #b10d21 !important;
}
.ui.basic.webo.red.button:hover {
  background: 0 0 !important;
  box-shadow: 0 0 0 1px #b10d21 inset !important;
  color: #b10d21 !important;
}
.ui.basic.webo.red.buttons .active.button {
  background: 0 0 !important;
  box-shadow: 0 0 0 1px #b4091e inset !important;
  color: #921222 !important;
}
.ui.basic.webo.red.buttons .button {
  box-shadow: 0 0 0 1px #c0172c inset !important;
  color: #c0172c !important;
}
.ui.basic.webo.red.buttons .button:active {
  box-shadow: 0 0 0 1px #921222 inset !important;
  color: #921222 !important;
}
.ui.basic.webo.red.buttons .button:focus {
  background: 0 0 !important;
  box-shadow: 0 0 0 1px #a90519 inset !important;
  color: #b10d21 !important;
}
.ui.basic.webo.red.buttons .button:hover {
  background: 0 0 !important;
  box-shadow: 0 0 0 1px #b10d21 inset !important;
  color: #b10d21 !important;
}
.ui.basic.webo.redish.active.button {
  background: 0 0 !important;
  box-shadow: 0 0 0 1px #a55666 inset !important;
  color: #8f525e !important;
}
.ui.basic.webo.redish.button {
  box-shadow: 0 0 0 1px #aa6a77 inset !important;
  color: #aa6a77 !important;
}
.ui.basic.webo.redish.button:active {
  box-shadow: 0 0 0 1px #8f525e inset !important;
  color: #8f525e !important;
}
.ui.basic.webo.redish.button:focus {
  background: 0 0 !important;
  box-shadow: 0 0 0 1px #9c4f5f inset !important;
  color: #a35867 !important;
}
.ui.basic.webo.redish.button:hover {
  background: 0 0 !important;
  box-shadow: 0 0 0 1px #a35867 inset !important;
  color: #a35867 !important;
}
.ui.basic.webo.redish.buttons .active.button {
  background: 0 0 !important;
  box-shadow: 0 0 0 1px #a55666 inset !important;
  color: #8f525e !important;
}
.ui.basic.webo.redish.buttons .button {
  box-shadow: 0 0 0 1px #aa6a77 inset !important;
  color: #aa6a77 !important;
}
.ui.basic.webo.redish.buttons .button:active {
  box-shadow: 0 0 0 1px #8f525e inset !important;
  color: #8f525e !important;
}
.ui.basic.webo.redish.buttons .button:focus {
  background: 0 0 !important;
  box-shadow: 0 0 0 1px #9c4f5f inset !important;
  color: #a35867 !important;
}
.ui.basic.webo.redish.buttons .button:hover {
  background: 0 0 !important;
  box-shadow: 0 0 0 1px #a35867 inset !important;
  color: #a35867 !important;
}
.ui.bottom.attached.menu > .item:first-child {
  border-radius: 0;
}
.ui.buttons:not(.vertical) > .basic.webo.grey.button:not(:first-child) {
  margin-left: -1px;
}
.ui.buttons:not(.vertical) > .basic.webo.greyish.button:not(:first-child) {
  margin-left: -1px;
}
.ui.buttons:not(.vertical) > .basic.webo.red.button:not(:first-child) {
  margin-left: -1px;
}
.ui.buttons:not(.vertical) > .basic.webo.redish.button:not(:first-child) {
  margin-left: -1px;
}
.ui.compact.menu .item:last-child {
  border-radius: 0;
  border-radius: 0;
}
.ui.image > .ui.ribbon.label {
  left: calc(-0.05rem - 1.2em);
}
.ui.menu {
  border-radius: 0;
}
.ui.menu .dropdown.item .menu {
  border-radius: 0;
}
.ui.menu > .item:first-child {
  border-radius: 0;
}
.ui.modal .scrolling.content {
  max-height: calc(80vh - 10em);
  overflow: auto;
}
.ui.pagination.menu .item:last-child {
  border-radius: 0;
}
.ui.secondary.menu .dropdown.item > .menu {
  border-radius: 0;
}
.ui.tabular.fluid.menu {
  width: calc(100% + (1px * 2)) !important;
}
.ui.text.menu .dropdown.item > .menu {
  border-radius: 0;
}
.ui.top.attached.menu > .item:first-child {
  border-radius: 0;
}
.ui.vertical.menu .dropdown.item .menu {
  border-radius: 0;
}
.ui.vertical.menu > .active.item:first-child {
  border-radius: 0;
}
.ui.vertical.menu > .active.item:last-child {
  border-radius: 0;
}
.ui.vertical.menu > .active.item:only-child {
  border-radius: 0;
}
.ui.vertical.menu > .item:first-child {
  border-radius: 0;
}
.ui.vertical.menu > .item:last-child {
  border-radius: 0;
}
.ui.webo.grey.active.button {
  background-color: #8a9aa2;
  color: #fff;
  text-shadow: none;
}
.ui.webo.grey.button {
  background-color: #9aa6ac;
  background-image: none;
  box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
  color: #fff;
  text-shadow: none;
}
.ui.webo.grey.button .active.button:active {
  background-color: #8a9aa2;
  color: #fff;
  text-shadow: none;
}
.ui.webo.grey.button:active {
  background-color: #7e8d95;
  color: #fff;
  text-shadow: none;
}
.ui.webo.grey.button:focus {
  background-color: #81939c;
  color: #fff;
  text-shadow: none;
}
.ui.webo.grey.button:hover {
  background-color: #8b9aa2;
  color: #fff;
  text-shadow: none;
}
.ui.webo.grey.buttons .active.button {
  background-color: #8a9aa2;
  color: #fff;
  text-shadow: none;
}
.ui.webo.grey.buttons .active.button:active {
  background-color: #8a9aa2;
  color: #fff;
  text-shadow: none;
}
.ui.webo.grey.buttons .button {
  background-color: #9aa6ac;
  background-image: none;
  color: #fff;
  text-shadow: none;
}
.ui.webo.grey.buttons .button:active {
  background-color: #7e8d95;
  color: #fff;
  text-shadow: none;
}
.ui.webo.grey.buttons .button:focus {
  background-color: #81939c;
  color: #fff;
  text-shadow: none;
}
.ui.webo.grey.buttons .button:hover {
  background-color: #8b9aa2;
  color: #fff;
  text-shadow: none;
}
.ui.webo.greyish.active.button {
  background-color: #908c95;
  color: #fff;
  text-shadow: none;
}
.ui.webo.greyish.button {
  background-color: #9d9aa1;
  background-image: none;
  box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
  color: #fff;
  text-shadow: none;
}
.ui.webo.greyish.button .active.button:active {
  background-color: #908c95;
  color: #fff;
  text-shadow: none;
}
.ui.webo.greyish.button:active {
  background-color: #838088;
  color: #fff;
  text-shadow: none;
}
.ui.webo.greyish.button:focus {
  background-color: #88848e;
  color: #fff;
  text-shadow: none;
}
.ui.webo.greyish.button:hover {
  background-color: #908c95;
  color: #fff;
  text-shadow: none;
}
.ui.webo.greyish.buttons .active.button {
  background-color: #908c95;
  color: #fff;
  text-shadow: none;
}
.ui.webo.greyish.buttons .active.button:active {
  background-color: #908c95;
  color: #fff;
  text-shadow: none;
}
.ui.webo.greyish.buttons .button {
  background-color: #9d9aa1;
  background-image: none;
  color: #fff;
  text-shadow: none;
}
.ui.webo.greyish.buttons .button:active {
  background-color: #838088;
  color: #fff;
  text-shadow: none;
}
.ui.webo.greyish.buttons .button:focus {
  background-color: #88848e;
  color: #fff;
  text-shadow: none;
}
.ui.webo.greyish.buttons .button:hover {
  background-color: #908c95;
  color: #fff;
  text-shadow: none;
}
.ui.webo.red.active.button {
  background-color: #b4091e;
  color: #fff;
  text-shadow: none;
}
.ui.webo.red.button {
  background-color: #c0172c;
  background-image: none;
  box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
  color: #fff;
  text-shadow: none;
}
.ui.webo.red.button .active.button:active {
  background-color: #b4091e;
  color: #fff;
  text-shadow: none;
}
.ui.webo.red.button:active {
  background-color: #921222;
  color: #fff;
  text-shadow: none;
}
.ui.webo.red.button:focus {
  background-color: #a90519;
  color: #fff;
  text-shadow: none;
}
.ui.webo.red.button:hover {
  background-color: #b10d21;
  color: #fff;
  text-shadow: none;
}
.ui.webo.red.buttons .active.button {
  background-color: #b4091e;
  color: #fff;
  text-shadow: none;
}
.ui.webo.red.buttons .active.button:active {
  background-color: #b4091e;
  color: #fff;
  text-shadow: none;
}
.ui.webo.red.buttons .button {
  background-color: #c0172c;
  background-image: none;
  color: #fff;
  text-shadow: none;
}
.ui.webo.red.buttons .button:active {
  background-color: #921222;
  color: #fff;
  text-shadow: none;
}
.ui.webo.red.buttons .button:focus {
  background-color: #a90519;
  color: #fff;
  text-shadow: none;
}
.ui.webo.red.buttons .button:hover {
  background-color: #b10d21;
  color: #fff;
  text-shadow: none;
}
.ui.webo.redish.active.button {
  background-color: #a55666;
  color: #fff;
  text-shadow: none;
}
.ui.webo.redish.button {
  background-color: #aa6a77;
  background-image: none;
  box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
  color: #fff;
  text-shadow: none;
}
.ui.webo.redish.button .active.button:active {
  background-color: #a55666;
  color: #fff;
  text-shadow: none;
}
.ui.webo.redish.button:active {
  background-color: #8f525e;
  color: #fff;
  text-shadow: none;
}
.ui.webo.redish.button:focus {
  background-color: #9c4f5f;
  color: #fff;
  text-shadow: none;
}
.ui.webo.redish.button:hover {
  background-color: #a35867;
  color: #fff;
  text-shadow: none;
}
.ui.webo.redish.buttons .active.button {
  background-color: #a55666;
  color: #fff;
  text-shadow: none;
}
.ui.webo.redish.buttons .active.button:active {
  background-color: #a55666;
  color: #fff;
  text-shadow: none;
}
.ui.webo.redish.buttons .button {
  background-color: #aa6a77;
  background-image: none;
  color: #fff;
  text-shadow: none;
}
.ui.webo.redish.buttons .button:active {
  background-color: #8f525e;
  color: #fff;
  text-shadow: none;
}
.ui.webo.redish.buttons .button:focus {
  background-color: #9c4f5f;
  color: #fff;
  text-shadow: none;
}
.ui.webo.redish.buttons .button:hover {
  background-color: #a35867;
  color: #fff;
  text-shadow: none;
}
.ui[class*='bottom attached'].menu {
  border-radius: 0;
}
.ui[class*='top attached'].menu {
  border-radius: 0;
}
.ui[class*='very relaxed'][class*='vertically divided'].grid > .row:before {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}
.ui.accordion .title:not(.ui),
.ui.button,
.ui.card > .content > .header,
.ui.cards > .card > .content > .header,
.ui.category.search > .results .category .result.active,
.ui.form input:not([type]),
.ui.form input[type='date'],
.ui.form input[type='datetime-local'],
.ui.form input[type='email'],
.ui.form input[type='file'],
.ui.form input[type='number'],
.ui.form input[type='password'] .ui.form input[type='search'],
.ui.form input[type='tel'] .ui.form input[type='text'],
.ui.form input[type='time'],
.ui.form input[type='url'],
.ui.header,
.ui.list .list > .item .header,
.ui.list > .item .header,
.ui.menu,
.ui.message .header,
.ui.modal > .header,
.ui.search > .results > .result .title,
.ui.search > .results .result .title,
.ui.statistic > .label,
.ui.statistic > .value,
.ui.statistics .statistic > .label,
.ui.statistics .statistic > .value,
.ui.steps .step .title,
.ui.text.container,
body,
h1,
h2,
h3,
h4,
h5 {
  font-family: 'Open Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}
